<template>
  <div class="event-attendees">
    <h3 class="pt-5 pb-3 primary--text">{{ header }}</h3>
    <v-app-bar flat color="transparent" class="main-appbar">
      <v-text-field
        v-model="filter.search"
        class="appbar__filter"
        ref="searchField"
        flat
        hide-details
        solo
        rounded
        clearable
        background-color="grey lighten-3"
        label="Search name, email or phone number"
        @input="search"
      ></v-text-field>

      <v-spacer></v-spacer>

      <div class="appbar__sort">
        <v-select
          class="ml-10"
          hide-details
          dense
          item-text="label"
          item-value="value"
          v-model="defaultFilter"
          :disabled="loading"
          :items="filterBy"
          style="max-width: 200px"
          @change="filterList"
        />

        <v-btn
          depressed
          class="ml-2"
          :disabled="loading"
          :color="sortOrder ? 'primary' : ''"
          @click="
            sortOrder = true
            filterList()
          "
        >
          <v-icon>{{ icons.arrowUp }}</v-icon>
        </v-btn>
        <v-btn
          depressed
          class="ml-2"
          :disabled="loading"
          :color="!sortOrder ? 'primary' : ''"
          @click="
            sortOrder = false
            filterList()
          "
        >
          <v-icon>{{ icons.arrowDown }}</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <div
      id="event-list"
      class="mx-0"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">Unit no.</th>
            <th class="text-left">Name</th>
            <th class="text-left">Email</th>
            <th class="text-left">Phone number</th>
            <th class="text-left">Guest(s)</th>
            <th width="20%" class="text-left">Join date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="attendee in attendees" :key="attendee.id">
            <td>{{ attendee.unit_number }}</td>
            <td>{{ attendee.full_name }}</td>
            <td>{{ attendee.email }}</td>
            <td>{{ attendee.phoneNumber }}</td>
            <td>{{ attendee.noOfGuests }}</td>
            <td>{{ attendee.joinDate }}</td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td v-for="index in 6" class="pl-0" :key="index">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(attendees.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Event: Attendees
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'EventAttendees',

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'join_date',
      sortOrder: false,
      filterBy: [
        {
          value: 'full_name',
          label: 'Name',
        },
        {
          value: 'email',
          label: 'Email address',
        },
        {
          value: 'plus',
          label: 'Guest',
        },
        {
          value: 'join_date',
          label: 'Join date',
        },
      ],
    }
  },

  created() {
    this.clearAttendees()
    this.fetch(1)
  },

  computed: {
    ...mapState({
      attendees: (state) => state.attendee.list,
      listMeta: (state) => state.attendee.listMeta,
    }),

    header() {
      return `Attendees (${this.totalItems})`
    },

    totalItems() {
      return this.listMeta && this.listMeta.total
        ? this.listMeta.total + this.listMeta.total_plus
        : 0
    },
  },

  methods: {
    ...mapActions({
      getAttendees: 'attendee/getAttendees',
    }),

    ...mapMutations({
      clearAttendees: 'attendee/clearAttendeeList',
    }),

    filterList(filterValue) {
      this.clearAttendees()
      this.fetch(null, filterValue)
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearAttendees()
      this.fetch()
    }, 600),

    async fetch(page = 1) {
      if (this.loading) return

      let params = {
        event_id: this.$route.params.id,
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getAttendees(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-toolbar {
  height: auto !important;

  .v-toolbar__content {
    height: auto !important;
    padding-right: 0;
    padding-left: 0;
  }

  @media (max-width: 540px) {
    .v-toolbar__content {
      flex-direction: column;
    }
  }
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
  }

  &__sort {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
      margin-bottom: 20px;
    }

    &__sort {
      width: 100%;

      .v-input {
        max-width: 100% !important;
        margin-left: 0 !important;
      }
    }
  }
}
</style>
